<template>
  <div>
    <div class="d-flex flex-wrap align-items-center">
      <h4 class="mb-0 mr-auto">
        {{ $t('store_list_page.store_list') }}
      </h4>
      <a-button
        v-if="permission.includes('WRITE')"
        icon="plus-square"
        class="add__button"
        size="large"
        @click="$router.push({name: 'add-store', query: {...getQueryParam($route.query)}})"
        @-click="openModalMW()"
      >
        {{ $t('store_list_page.add_store') }}
      </a-button>
    </div>
    <div class="row no-gutters my-3">
      <div class="col-md-4 d-flex align-items-center pr-md-4">
        Channel:
        <a-select
          class="flex-grow-1 ml-3 text-capitalize select-antd-default"
          size="large"
          :value="valueChannel"
          @change="onChangeChannel"
        >
          <a-select-option
            v-for="state in list_channel"
            :key="state.sales_channel_id"
            class="text-capitalize"
            :value="state.sales_channel_id"
          >
            {{ state.name }}
          </a-select-option>
        </a-select>
      </div>
      <div class="col-md-4 d-flex align-items-center">
        {{ $t('store_list_page.store_status') }}:
        <a-select
          class="flex-grow-1 ml-3 text-capitalize select-antd-default"
          size="large"
          :value="valueStatus"
          @change="onChangeStatus"
        >
          <a-select-option
            v-for="state in list_status"
            :key="state"
            class="text-capitalize"
            :value="state"
          >
            {{ state }}
          </a-select-option>
        </a-select>
      </div>
      <div class="col-md-4 d-flex align-items-center pl-md-4">
        {{ $t('store_list_page.country') }}:
        <a-select
          class="flex-grow-1 ml-3 select-antd-default"
          size="large"
          :value="valueCountry"
          @change="onChangeCountry"
        >
          <a-select-option
            v-for="country in COUNTRIES"
            :key="country.code"
            :value="country.code"
          >
            {{ country.name }}
          </a-select-option>
        </a-select>
      </div>
    </div>
    <div class="row mx-0">
      <div class="col-md-9 pl-0">
        <a-input-search
          :loading="loadingSearch"
          size="large"
          :placeholder="$t('store_list_page.search_placeholder')"
          :value="valueSearch"
          @change="onChangeSearch"
          @search="onSearch"
        >
          <a-button slot="enterButton" class="btn-search">
            {{ $t('utils.search') }} <a-icon type="search" />
          </a-button>
        </a-input-search>
      </div>
      <div class="col-md-3 pr-0 d-flex align-items-center">
        <div class="flexno pr-3">
          {{ `${$t('order.sortBy')}:` }}
        </div>
        <a-select
          class="w-100 ml-auto text-capitalize select-antd-default"
          size="large"
          :value="sortBy"
          option-filter-prop="children"
          @change="onChangeSort"
        >
          <a-select-option
            v-for="sort in sort_"
            :key="sort.value"
            class="text-capitalize"
            :value="sort.value"
          >
            {{ sort.label }}
          </a-select-option>
        </a-select>
      </div>
    </div>

    <TableBorder class="mt-3">
      <template #thead>
        <th v-for="th in THEAD" :key="th" scope="col" :style="{ width: th === 'action' ? '125px' : 'auto' }">
          {{ $t('store_list_page.' + th) }}
        </th>
      </template>
      <tbody v-if="data.length">
        <tr v-for="val in data" :key="val.id">
          <td>
            <div class="store-name" @click="onShowDrawerDetail(val)">
              <img
                :alt="val.title"
                draggable="false"
                width="30"
                height="30"
                class="mr-2"
                :style="{ objectFit: 'contain', padding: ['shopee_id','tokopedia_id', 'tiktok', 'tiktok_id', 'blibli_id', 'lazada_id'].includes(val.sales_channel?.code) ? '0' : '5px' }"
                :src="
                  val?.notes === 'tiktok_tokopedia'
                    ? require(`@/assets/store/${val?.notes}.png`)
                    : ['shopee_id','tokopedia_id', 'tiktok', 'tiktok_id', 'blibli_id', 'lazada_id'].includes(val.sales_channel?.code)
                      ? require(`@/assets/store/${val.sales_channel?.code.toLowerCase()}.png`)
                      : require('@/assets/images/logo_small.png')
                "
              />
              {{ val.title }}
            </div>
          </td>
          <td>{{ val?.sales_channel?.name || '-' }}</td>
          <td
            v-if="val.status"
            :class="val.status === 'CONNECTED'
              ? 'st-connect'
              : val.status === 'DISCONNECTED'
                ? 'st-disconnected'
                : 'st-pending'
            "
          >
            {{
              val.status === 'CONNECTED'
                ? 'Terhubung'
                : val.status === 'DISCONNECTED'
                  ? 'Terputus'
                  : val.status === 'WAITING'
                    ? 'Menunggu'
                    : val.status === 'PENDING_WAREHOUSE'
                      ? 'Pending Warehouse'
                      : val.status
            }}
          </td>
          <td v-else>
            {{ '-' }}
          </td>
          <td>
            <time>{{
              val.connected_at
                ? $moment(val.connected_at, 'YYYY-MM-DD HH:mm').format(
                  'DD-MM-YYYY HH:mm',
                )
                : '-'
            }}</time>
          </td>
          <!-- <td>
            {{
              val.sales_channel.country_name
                ? val.sales_channel.country_name
                : '-'
            }}
          </td> -->
          <!-- <td>
            <ConsoleLink
              v-if="!val.confirmed"
              :to="{
                name: 'store.product-synchronize',
                params: { id: val.id },
              }"
              class="text-primary font-weight-bold"
            >
              {{ val.notes ? val.notes : '-' }}
            </ConsoleLink>
          </td> -->
          <td class="action-btn" style="text-align: right;">
            <a-dropdown>
              <a @click.prevent="">
                <a-icon type="more" />
              </a>
              <template #overlay>
                <a-menu>
                  <a-menu-item
                    v-if="permission.includes('WRITE') && 
                      val.sales_channel.code === 'tokopedia_id' && 
                      $store.state.user.role_name?.toLowerCase() === 'super user' &&
                      val?.status?.toUpperCase() === 'WAITING'"
                    key="1"
                    class="py-2"
                    @click="showModal(val)"
                  >
                    Approve
                  </a-menu-item>
                  <a-menu-item
                    v-if="permission.includes('WRITE') && val?.status?.toUpperCase() === 'PENDING_WAREHOUSE'"
                    key="2"
                    class="py-2"
                    @click="openModalAddWarehouse(val)"
                  >
                    <a-icon type="bank" />
                    Mapping Warehouse
                  </a-menu-item>
                  <a-menu-item
                    v-if="permission.includes('WRITE') && 
                      ['blibli_id'].includes(val.sales_channel.code) && 
                      $route.query.business_id && 
                      val?.status?.toUpperCase() === 'CONNECTED'"
                    key="3"
                    class="py-2"
                    @click="updateStoreStatus(val, val.sales_channel.code)"
                  >
                    <a-icon type="check-circle" class="menu-reconnect" />
                    {{ val?.detail?.auto_accept ? 'Non Aktifkan' : 'Aktifkan' }} Auto Accept Order
                  </a-menu-item>
                  <a-menu-item
                    v-if="permission.includes('WRITE') && 
                      val.sales_channel.code === 'blibli_id' && 
                      $route.query.business_id && 
                      val?.status?.toUpperCase() === 'CONNECTED'"
                    key="5"
                    &&
                    class="py-2"
                    @click="showWarning(val)"
                  >
                    Non Aktifkan
                  </a-menu-item>
                  <a-menu-item
                    v-if="permission.includes('WRITE') && 
                      ['shopee_id', 'tokopedia_id', 'tiktok', 'tiktok_id', 'lazada_id'].includes(val.sales_channel.code) && 
                      $route.query.business_id && 
                      ['CONNECTED', 'PENDING_WAREHOUSE'].includes(val?.status?.toUpperCase())"
                    key="6"
                    class="py-2 d-flex align-items-center"
                    @click="onPopconfirmDisconnectStore(val.sales_channel.code, val.id)"
                  >
                    <a-icon type="close-circle" class="menu-disconnect" />
                    Putuskan
                  </a-menu-item>
                  <a-menu-item
                    v-if="permission.includes('WRITE') && 
                      ['shopee_id', 'lazada_id', 'tiktok', 'tiktok_id', 'tokopedia_id'].includes(val.sales_channel.code) && 
                      $route.query.business_id && 
                      val?.status?.toUpperCase() === 'CONNECTED'"
                    key="6"
                    class="py-2 d-flex align-items-center"
                    @click="dataEditWarehouse = val, showModalEditWarehouse = true"
                  >
                    <a-icon type="bank" />
                    Ubah Mapping Gudang
                  </a-menu-item>
                  <a-menu-item
                    v-if="permission.includes('WRITE') && 
                      ['shopee_id', 'tokopedia_id', 'tiktok', 'tiktok_id'].includes(val.sales_channel.code) && 
                      $route.query.business_id && 
                      val?.status?.toUpperCase() === 'DISCONNECTED'"
                    key="7"
                    class="py-2 d-flex align-items-center"
                    @click="onReconnectChannel(val.sales_channel.code, val.id)"
                  >
                    <a-icon type="check-circle" class="menu-reconnect" />
                    Sambungkan Kembali
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </td>
        </tr>
      </tbody>
    </TableBorder>

    <LoadingListTable v-if="loadingData" />
    <template v-else>
      <ErrorInfo v-if="errorRows" :error-type="errorRows" />
      <EmptyInfo
        v-else-if="!errorRows && !data.length"
        :title="$t('store_list_page.empty_title')"
        :description="$t('store_list_page.empty_msg')"
      />
    </template>

    <Pagination
      v-if="!loadingData && data.length"
      class="text-right"
      :total="total_row"
      :page-size="pageSize"
      :total-length-per-page="data.length"
      :default-page="defaultPage"
      @changePage="onChangePage"
    />
    <ModalBusiness
      :show-modal-edit="showModalEdit"
      :show-success="showSuccess"
      :show-failed="showFailed"
      :row-data="rowData"
      @handleShowModal="handleShowModal"
      @handleSubmitEdit="handleSubmitEdit"
    />

    <!-- Modal add marketplace or warehouse -->
    <modal-list-marketplace-warehouse
      :visible="modalListMW"
      :open-modal-m-w="openModalMW"
      :open-modal-sync="openModal"
    />
    <modal-approve-confirmation
      :visible="modalApproveConfirmation"
      :open-modal="actionModalApproveConfirmation"
      :selected-data="selectedData"
      @emitSuccessApprove="onSuccessApprove"
    />
    <modal-add-warehouse-channel
      :visible="showModalAddWarehouseChannel"
      :open-modal="actionModalAddWarehouseChannel"
      :selected-data="selectedData"
      @emitSuccessAddWarehouse="onSuccessAddWarehouse"
    />
    <modal-sync
      :visible="modalSync"
      :back="openModalMW"
      :open-modal="openModal"
      :open-modal-success="openModalSuccess"
      :id-data="idData"
    />
    <modal-success-sync
      :visible="modalSuccessSync"
      :back="openModalMW"
      :open-modal-success="openModalSuccess"
    />
    <ModalDisconnectStore
      :visible="showModalDisconnectStore"
      :data="dataDisconnectStore"
      :loading="loadingModalDisconnectStore"
      @handleShowModal="handleShowModalDisconnectStore"
      @handleSubmitModal="onDisconnectChannel"
    />
    <!-- <ModalEditWarehouse
      :visible="showModalEditWarehouse"
      :data="dataEditWarehouse"
      @onCancel="handleShowModalEditWarehouse"
      @onOk="handleSubmitEditWarehouse"
      @onRefreshList="onRequest"
    /> -->
    <ModalMappingWarehouse
      :visible="showModalEditWarehouse"
      :data="dataEditWarehouse"
      @onCancel="handleShowModalEditWarehouse"
      @onOk="handleSubmitEditWarehouse"
      @onRefreshList="onRequest"
    />
    <a-drawer
      title="Detail Toko"
      placement="right"
      :closable="true"
      :visible="viewDetail"
      width="500"
      @close="viewDetail = false, dataDrawerDetail = null"
    >
      <div class="d-flex flex-column drawer-container">
        <div class="d-flex">
          <div class="w-50">
            <div class="font-weight-bold">
              Nama Toko
            </div>
            <div>{{ dataDrawerDetail?.title || '-' }}</div>
          </div>
          <!-- <div class="w-50">
            <div class="font-weight-bold">
              Last Product Sync
            </div>
            <div>-</div>
          </div> -->
        </div>
        <div class="d-flex">
          <div class="w-50">
            <div class="font-weight-bold">
              Status Toko
            </div>
            <div>{{ dataDrawerDetail?.status ?? '-' }}</div>
          </div>
          <!-- <div class="w-50">
            <div class="font-weight-bold">
              Last Order Sync
            </div>
            <div>-</div>
          </div> -->
        </div>
        <div class="d-flex whs">
          <div class="w-100">
            <div class="font-weight-bold">
              Warehouse
            </div>
            <div v-if="!loadingWarehouse && !warehouseList.length">
              -
            </div>
            <a-skeleton v-else :loading="loadingWarehouse" :paragraph="{ rows: 0 }" active>
              <a-divider class="my-2" />
              <div class="d-flex font-weight-bold" style="font-size: 12px;">
                <div class="w-50">
                  Warehouse Channel
                </div>
                <div class="w-50">
                  Warehouse Master
                </div>
              </div>
              <div v-for="warehouse in warehouseList" :key="warehouse.warehouse_master_id">
                <a-divider class="my-2" />
                <div class="d-flex">
                  <div class="w-50">
                    {{ warehouse.warehouse_channel_name }}
                  </div>
                  <div class="w-50">
                    {{ warehouse.warehouse_master_name }}
                  </div>
                </div>
                <div v-if="warehouse.updated_at" style="font-size: 10px; color: grey">
                  Last updated: <span>{{ $moment(warehouse.updated_at).format('HH:mm:ss, DD-MMM-YYYY') }}</span>
                </div>
              </div>
              <a-divider class="my-2" />
            </a-skeleton>
          </div>
          <!-- <div class="w-50">
            <div class="font-weight-bold">
              Auto confirm
            </div>
            <div>-</div>
          </div> -->
        </div>
        <div class="d-flex">
          <div class="w-100">
            <div class="font-weight-bold">
              Channel ID
            </div>
            <div>{{ dataDrawerDetail?.id || '-' }}</div>
          </div>
        </div>
        <div v-if="['tiktok', 'tiktok_id'].includes(dataDrawerDetail?.sales_channel?.code)" class="d-flex">
          <div class="w-100">
            <div class="font-weight-bold">
              Status Auto Accept
            </div>
            <div class="mt-1">
              <a-popconfirm
                :title="`Apakah anda yakin ingin ${dataDetailChannel.auto_accept ? 'menonaktifkan' : 'mengaktifkan'} auto accept pada channel ini?`"
                :ok-text="`Ya, ${dataDetailChannel.auto_accept ? 'Non-aktifkan' : 'Aktifkan'}`"
                cancel-text="Batalkan"
                @confirm.prevent="onChangeAutoAccept"
              >
                <a-switch size="large" :checked="dataDetailChannel.auto_accept" :loading="fetchingUpdateAutoAccept" />
              </a-popconfirm>
            </div>
          </div>
        </div>
        <!-- <div class="d-flex">
          <div class="w-100">
            <div class="font-weight-bold">
              API Key
            </div>
            <div>-</div>
          </div>
        </div>
        <div class="d-flex">
          <div class="w-100">
            <div class="font-weight-bold">
              Store URL
            </div>
            <div>-</div>
          </div>
        </div> -->
        <div v-if="dataDrawerDetail?.sales_channel?.code === 'tokopedia_id' || dataDrawerDetail?.notes === 'tiktok_tokopedia'" class="binding-box">
          <div class="binding-box-image">
            <div>
              <img :src="require('@/assets/store/tokopedia.png')" />
              <img :src="require('@/assets/store/switch-horizontal.png')" />
              <img :src="require('@/assets/store/tiktok-shop.png')" />
            </div>
          </div>
          <div v-if="dataDrawerDetail?.notes === 'tiktok_tokopedia'">
            <h3 class="mb-0">
              Anda telah melakukan Binding
            </h3>
          </div>
          <div v-else>
            <h3>Baca Ketentuan berikut:</h3>
            <ol>
              <li>Pastikan sebelumnya anda telah melakukan binding pada seller centre Tokopedia</li>
              <li>Anda tidak dapat unbinding jika proses binding telah dilakukan</li>
              <li>Pastikan anda memilih channel Tokopedia yang sama saat melakukan binding di seller centre Tokopedia anda</li>
              <li>Pada saat proses binding anda akan diminta melakukan login akun TikTok Kembali.</li>
              <li>Pastikan anda login ke akun TikTok yang anda tuju dan yang telah dilakukan binding pada seller centre Tokopedia</li>
              <li>Segala kesalahan pemilihan akun Tokopedia & TikTok pada saat proses binding ini menjadi tanggung jawab anda sepenuhnya.</li>
              <li>Jika Anda telah yakin, silahkan klik tombol "Binding Sekarang"</li>
            </ol>
            <a-checkbox v-model="isAgreeBinding" class="binding-box-checkbox" :disabled="isBinding">
              Saya telah membaca dan mengerti ketentuan binding Tokopedia x TikTok Shop
            </a-checkbox>
            <a-button
              class="mt-3"
              type="primary"
              block
              :disabled="!isAgreeBinding || isBinding"
              :loading="isBinding"
              @click="bindTiktok(dataDrawerDetail?.sales_channel?.code, dataDrawerDetail?.id)"
            >
              <a-icon type="disconnect" /> Binding Sekarang
            </a-button>
          </div>
        </div>
      </div>
    </a-drawer>
    <a-modal
      :visible="visible"
      title="Setujui Integrasi Tokopedia"
      :closable="false"
      :footer="null"
    >
      <div>
        <ol class="pl-3 text-left">
          <li class="text-desc" style="text-align: justify;">
            Untuk menyetujui integrasi toko [{{ rowData.title }}] anda perlu memastikan bahwa status toko berstatus aktif pada dev console Tokopedia :
          </li>
        </ol>
        <div class="mb-3" style="color: #7777FF; font-style: italic">
          dev console > app management > pilih live apps yang diinginkan > shop management
        </div>
        <ol start="2" class="pl-3 text-left">
          <li class="text-desc" style="text-align: justify;">
            Masukkan App ID Tokopedia dari live apps yang digunakan untuk mendaftarkan toko [{{ rowData.title }}] sesuai yang ada pada dev console Tokopedia.
          </li>
        </ol>
        <a-input v-model="approval.app_id" class="mb-3" placeholder="Masukkan App ID" />
        <ol start="3" class="pl-3 text-left">
          <li class="text-desc" style="text-align: justify;">
            Input shop ID dari toko [{{ rowData.title }}] pada bidang berikut
          </li>
        </ol>
        <a-input v-model="approval.shop_id" placeholder="Masukkan Shop ID" />
        <div class="mt-5" style="text-align: right">
          <a-button
            class="mr-2"
            :loading="loadingApprove"
            @click="showModal({})"
          >
            Batal
          </a-button>
          <a-button
            type="primary"
            :loading="loadingApprove"
            :disabled="!approval.shop_id || !approval.app_id"
            @click="onApprove"
          >
            Setujui
          </a-button>
        </div>
      </div>
    </a-modal>

    <a-modal :visible="warningVisible" :width="successDisconnect ? 350 : 450" :closable="false" :footer="null">
      <div class="warning-logo">
        <a-icon v-if="successDisconnect" type="check-circle" theme="twoTone" two-tone-color="#52c41a" />
        <a-icon v-else type="warning" theme="twoTone" two-tone-color="#faad14" />
      </div>
      <div v-if="loadingBlibliData" class="warning-loading">
        <a-skeleton :loading="loadingBlibliData" :paragraph="{ rows: 1, width: '100%' }" active />
      </div>
      <div v-else class="warning-description">
        <p v-if="successDisconnect" class="mb-0">
          Toko Blibli Anda - <b>{{ store.name }}</b> berhasil dinonaktifkan dari aplikasi POWERBIZ
        </p>
        <p v-else class="mb-0">
          Apakah Anda yakin ingin menonaktifkan toko Blibli Anda - <b>{{ store.name }}</b> dari aplikasi POWERBIZ ?
        </p>
      </div>
      <div class="warning-button">
        <a-row :gutter="16">
          <a-col :span="successDisconnect ? 24 : 12">
            <a-button
              v-if="successDisconnect"
              type="primary"
              size="large"
              class="w-100"
              :disabled="loadingBlibliData || loadingDisconnect"
              @click="refreshPage"
            >
              Oke
            </a-button>
            <a-button
              v-else
              type="primary"
              size="large"
              class="w-100"
              :disabled="loadingBlibliData || loadingDisconnect"
              @click="warningVisible = false"
            >
              Batal
            </a-button>
          </a-col>
          <a-col :span="12">
            <a-button
              v-if="!successDisconnect"
              type="primary"
              size="large"
              class="w-100"
              ghost
              :loading="loadingBlibliData || loadingDisconnect"
              @click="disconnectStore"
            >
              Ya
            </a-button>
          </a-col>
        </a-row>
      </div>
    </a-modal>
  </div>
</template>

<script>
// import { defineComponent } from 'vue'
// import moment from 'moment'
import LoadingListTable from '@/components/Loading/ListTable'
import EmptyInfo from '@/components/EmptyInfo'
import ErrorInfo from '@/components/ErrorInfo'
import TableBorder from '@/components/Table/TableBorder.vue'
import Pagination from '@/components/Pagination/index.vue'
// import FLAG_ID from '@/assets/general/flags/ID.svg'
// import FLAG_MY from '@/assets/general/flags/MY.svg'
import ModalBusiness from '@/components/Store/ModalBusiness'
import ModalDisconnectStore from '@/components/Store/ModalDisconnectStore'
// import ModalEditWarehouse from '@/views/store/ModalEditWarehouse.vue'
import ModalMappingWarehouse from '@/views/store/ModalMappingWarehouse.vue'
// import ConsoleLink from '@/components/ConsoleLink/ConsoleLink.vue'
import ModalListMarketplaceWarehouse from '@/components/Store/AddStore/ModalListMarketplaceWarehouse.vue'
import { getLazadaWarehouseChannelList } from '@/api/channels/lazada'
/* Add Marketplace & Warehouse */
import addStore from '@/composable/store/addStore'
import ModalSync from '@/components/Store/AddStore/ModalSync.vue'
import ModalSuccessSync from '@/components/Store/AddStore/ModalSuccessSync.vue'
import getQueryParam from '@/utils/getQueryParam'
import ModalApproveConfirmation from '@/components/Store/AdminStore/ModalApproveConfirmation.vue'
import ModalAddWarehouseChannel from '@/components/Store/AdminStore/ModalAddWarehouseChannel.vue'
import { disconnectChannelWithPause, reconnectChannelWithPause, getChannelWarehouse, updateAutoAcceptChannel, getDetailChannel } from '@/api/channels/index'
import { getBlibliDataDetail, getBlibliPickupPoint, updateAutoAcceptOrder, disconnectBlibli } from '@/api/channels/blibli'
import { confirmIntegrationTokopedia } from '@/api/channels/tokopedia'
import debounce from 'lodash/debounce'
import { disconnectTiktokIntegration } from '@/api/channels/tiktok'

const SORT_BY = 'alphabetical'
export default {
  name: 'ProductConfirmation',
  components: {
    LoadingListTable,
    EmptyInfo,
    ErrorInfo,
    TableBorder,
    Pagination,
    ModalBusiness,
    // ConsoleLink,
    ModalListMarketplaceWarehouse,
    ModalSync,
    ModalSuccessSync,
    ModalApproveConfirmation,
    ModalAddWarehouseChannel,
    ModalDisconnectStore,
    // ModalEditWarehouse,
    ModalMappingWarehouse,
  },
  mixins: [getQueryParam],
  setup(props) {
    const {
      dataMarketPlace,
      dataFulfilment,
      modalSync,
      modalSuccessSync,
      modalListMW,
      openModalMW,
      openModal,
      openModalSuccess,
      idData,
    } = addStore(props)

    return {
      dataMarketPlace,
      dataFulfilment,
      modalSync,
      modalSuccessSync,
      modalListMW,
      openModalMW,
      openModal,
      openModalSuccess,
      idData,
    }
  },
  data() {
    return {
      // FLAGS: {
      //   228: FLAG_ID,
      //   229: FLAG_MY,
      // },
      COUNTRIES: [],
      THEAD: [
        'store_name',
        'sales_channel',
        'store_status',
        'authorization_time',
        'action',
      ],
      hitApi: 0,
      loadingData: true,
      loadingSearch: false,
      loadingBlibliData: false,
      loadingDisconnect: false,
      loadingWarehouse: false,
      valueSearch: '',
      valueChannel: 'all',
      valueStatus: 'Semua Status',
      valueCountry: 'all',
      sortBy: SORT_BY,
      total_row: 20,
      pageSize: 20,
      defaultPage: 1,
      data: [],
      errorRows: false,
      list_channel: [],
      list_status: [],
      showModalEdit: false,
      showSuccess: false,
      showFailed: false,
      rowData: {},
      sort_: [
        {
          label: 'Alfabetis',
          value: 'alphabetical',
        },
        {
          label: 'Numerik',
          value: 'numeric',
        },
      ],
      warehouseList: [],
      viewDetail: false,
      dataDrawerDetail: null,
      modalApproveConfirmation: false,
      selectedData: null,
      showModalAddWarehouseChannel: false,
      visible: false,
      warningVisible: false,
      successDisconnect: false,
      store: {
        business_id: null,
        id: null,
        name: null,
        channel_id: null,
      },
      approval: {
        shop_id: '',
        aggregator_id: '',
        app_id: '',
      },
      loadingApprove: false,
      showModalDisconnectStore: false,
      loadingModalDisconnectStore: false,
      dataDisconnectStore: null,
      showModalEditWarehouse: false,
      dataEditWarehouse: null,
      dataDetailChannel: {},
      fetchingUpdateAutoAccept: false,
      isBinding: false,
      isAgreeBinding: false,
    }
  },
  computed: {
    permission() {
      return this.$store.getters['user/can'](this.$route.meta.key)
    },
  },
  watch: {
    '$route.query'() {
      this.onRequest()
    },
    warningVisible(isVisible) {
      if(!isVisible) {
        this.successDisconnect = false
        this.store = {
          business_id: null,
          id: null,
          name: null,
          channel_id: null,
        }
      }
    },
    viewDetail(value) {
      if (!value) this.isAgreeBinding = false
    },
    // permission(newValue) {
    //   if(!this.$route.query.business_id && !newValue.length) {
    //     this.$router.push({path: '/error/403', query: {...this.$route.query}})
    //   }
    // },
  },
  mounted() {
    this.onRequest()
  },
  methods: {
    async bindTiktok(channelCode, channelId) {
      if (channelId && channelCode === 'tokopedia_id') {
        this.isBinding = true
        const route = {
          path: '/add-store',
          query: {
            ...this.$route.query,
            'company-aggregator-id': 123,
            name: 'tiktok',
          },
        }
        localStorage.setItem('pathCallback', JSON.stringify(route))
        localStorage.setItem('channelId', channelId)
        localStorage.setItem('needBinding', true)
        const { data } = await this.$store.dispatch('integration/SYNCTIKTOK', 123)
        window.location = data.data.authorized_url
      }
    },
    async onApprove() {
      this.loadingApprove = true
      try {
        await confirmIntegrationTokopedia({
          id: this.rowData.id,
          business_id: this.$route.query.business_id,
          data: { ...this.approval },
        })
      } catch (err) {
        if (err) this.$message.error('Oops, inventory produk gagal disimpan', 5)
        throw err
      } finally {
        this.loadingApprove = false
        this.showModal({})
        this.onRequest()
      }
    },
    async showWarning(row) {
      this.loadingBlibliData = true
      this.warningVisible = true
      await getBlibliDataDetail({
        business_id: this.$route.query.business_id,
        channel_id: row?.id,
      })
      .then(({ data: { data: response } }) => {
        this.store = {
          business_id: response?.business_id,
          id: response?.store_id,
          name: response?.store_name,
          channel_id: response?.channel_id,
        }
      })
      .catch(err => {
        console.error(err)
        this.store = {
          business_id: null,
          id: null,
          name: null,
          channel_id: null,
        }
      })
      .finally(() => this.loadingBlibliData = false)
    },
    async updateStoreStatus(value, channelCode) {
      if (['blibli_id'].includes(channelCode)) {
        await updateAutoAcceptOrder({
          business_id: value?.detail?.business_id || this.$route.query.business_id,
          data: {
            store_id: value?.detail?.store_id || '',
            channel_id: value?.detail?.channel_id || 0,
            auto_accept: !value?.detail?.auto_accept,
          },
        })
        .then(({ data }) => {
          const notificationType = data.status.toLowerCase() === 'failed'
            ? 'error' : 'success'
          const notificationMessage = data.status.toLowerCase() === 'failed'
            ? 'Gagal' : 'Berhasil'
          const notificationDescription = value?.detail?.auto_accept
            ? 'Non Aktifkan' : 'Aktifkan'
          this.$notification[notificationType]({
            message: data?.code || `Ubah Auto Accept Order ${notificationMessage}`,
            description: data?.message || 
              `Status Auto Accept Order ${value?.title || value?.sales_channel?.name} ${notificationMessage} di ${notificationDescription}`,
          })
          if(data.status.toLowerCase() !== 'failed') this.onRequest()
        })
        .catch(err => {
          this.$notification.error({
            message: err?.code || 'Ubah Auto Accept Order Gagal',
            description: err?.message,
          })
        })
      }
    },
    async disconnectStore() {
      this.loadingDisconnect = true
      await disconnectBlibli({
        business_id: this.store.business_id,
        data: {
          store_id: this.store.id,
          channel_id: this.store.channel_id,
        },
      })
      .then(({ data }) => {
        if(data?.status?.toLowerCase() === 'success') {
          if(data?.data?.status?.toLowerCase() === 'disconnected') {
            this.successDisconnect = true
            this.disconnectNotification('success')
          } else {
            this.getDisconnectStatus(data?.request_id)
          }
        } else {
          this.disconnectNotification('error')
        }
      })
      .catch(err => {
        console.error(err)
        this.disconnectNotification('error')
      })
    },
    getDisconnectStatus: debounce(async function(request_id) {
      await getBlibliDataDetail({
        business_id: this.store.business_id,
        channel_id: this.store.channel_id,
      })
      .then(({ data: { data: response } }) => {
        if(response?.status === 'DISCONNECTED') {
          this.successDisconnect = true
          this.disconnectNotification('success')
        } else {
          this.hitApi++
          if(this.hitApi > 10) {
            this.disconnectNotification('error')
          } else {
            this.getDisconnectStatus(request_id)
          }
        }
      })
      .catch(err => {
        console.error(err)
        this.disconnectNotification('error')
      })
    }, 1500),
    disconnectNotification(type) {
      const status = type === 'success' ? 'Berhasil' : 'Gagal'
      this.hitApi = 0
      this.loadingDisconnect = false
      this.$notification[type]({
        message: status,
        description: `Proses menonaktifkan toko ${status}`,
      })
    },
    showModal(row) {
      this.visible = !this.visible
      this.rowData = row
      this.shop_id = ''
    },
    handleShowModal(type, param, row) {
      switch (type) {
        case 'edit':
          this.showModalEdit = param
          this.rowData = row
          break
        case 'close':
          this.showModalEdit = param
          break
      }
    },
    handleShowModalDisconnectStore(param) {
      this.showModalDisconnectStore = param
      this.dataDisconnectStore = null
    },
    handleSubmitEdit() {
      this.showModalEdit = false
      this.showSuccess = true
    },
    handleShowModalEditWarehouse(param) {
      this.showModalEditWarehouse = param
      this.dataEditWarehouse = null
    },
    handleSubmitEditWarehouse(data) {
      this.showModalEditWarehouse = false
    },
    onRequest() {
      const { page, status, country_id, sales_channel_id, keyword } = this.$route.query
      this.loadingData = true
      this.data = []
      this.defaultPage = +page || 1

      let query = {
        ...this.$route.query,
        page: this.defaultPage,
      }

      if(this.$store.state.user.role_id === '73ed8e78-bb8f-4d90-8547-d5fb54d44b82') {
          query = { ...query, workspace_id: undefined, business_id: undefined }
        }

      // if (!query.business_id) {
      //   this.$router.push({
      //     name: '/dashboard',
      //   })
      // }

      // setTimeout(() => { // DUMMY XHR
      //   this.data = Array.from({ length: 20 }).map((val, idx) => {
      //     const bool = Boolean(idx % 2)
      //     return {
      //       id: idx + 1,
      //       status: bool,
      //       confirmed: bool,
      //       country: bool ? 'MY' : 'ID',
      //       product_qty: bool ? 0 : 18,
      //     }
      //   })
      //   this.total_row = 30
      //   this.loadingData = false
      //   // this.errorRows = 404
      // }, 2e3)

      this.$store
        .dispatch('channel/GETCHANNEL', {
          ...query,
        })
        .then(async (res) => {
          this.total_row = res.total_row
          this.data = res?.data?.length
            ? await Promise.all(res.data.map(async (channel) => {
              const detail = channel.sales_channel.code === 'blibli_id'
                ? await this.getDetailData(channel.id)
                : {}
              channel.detail = detail
              return channel
            }))
            : []

          this.COUNTRIES = res.country_data
            .filter((v) => {
              return v.id
            })
            .map((v) => {
              return {
                code: v.id,
                name: v.name,
              }
            })
          this.list_status = res.status_data.filter((v) => {
            return v
          })
          this.list_channel = res.sales_channel_data.map((v) => {
            return {
              sales_channel_id: v.id,
              name: v.name,
            }
          })

          this.list_channel.push({
            sales_channel_id: 'all',
            name: 'Semua Channel',
          })
          this.COUNTRIES.push({
            code: 'all',
            name: 'Semua Negara',
          })
          this.list_status.push('Semua Status')

          this.list_channel.unshift(this.list_channel.pop())
          this.list_status.unshift(this.list_status.pop())
          this.COUNTRIES.unshift(this.COUNTRIES.pop())

          this.valueSearch = keyword || ''
          this.valueChannel = Number(sales_channel_id) || 'all',
          this.valueStatus = status || 'Semua Status',
          this.valueCountry = Number(country_id) || 'all',
          this.loadingData = false
        })
        .catch((err) => {
          console.error('err: ', err)
          this.loadingData = false
        })
    },
    async getDetailData(channel_id) {
      return await getBlibliDataDetail({
        business_id: this.$route.query.business_id,
        channel_id,
      })
      .then(({ data: { data: response } }) => response || {})
      .catch(() => {})
    },
    onChangeStatus(status) {
      this.valueStatus = status
      if (status === 'Semua Status') {
        let temp = {
          ...this.$route.query,
        }
        delete temp.status
        this.$router.push({
          query: { ...temp },
        })
      } else {
        this.$router.push({
          query: { ...this.$route.query, status },
        })
      }
    },
    onChangeChannel(sales_channel_id) {
      this.valueChannel = sales_channel_id
      if (sales_channel_id === 'all') {
        let temp = {
          ...this.$route.query,
        }
        delete temp.sales_channel_id
        this.$router.push({
          query: { ...temp },
        })
      } else {
        this.$router.push({
          query: { ...this.$route.query, sales_channel_id },
        })
      }
    },
    onChangeCountry(country_id) {
      this.valueCountry = country_id
      if (country_id === 'all') {
        let temp = {
          ...this.$route.query,
        }
        delete temp.country_id
        this.$router.push({
          query: { ...temp },
        })
      } else {
        this.$router.push({
          query: { ...this.$route.query, country_id },
        })
      }
    },
    onSearch(val) {
      this.valueSearch = val
      if (val === '') {
        let temp = {
          ...this.$route.query,
        }
        delete temp.keyword
        this.$router.push({
          query: { ...temp },
        })
      } else {
        this.$router.push({
          query: { ...this.$route.query, keyword: val },
        })
      }
    },
    onChangeSort(sort_by) {
      this.sortBy = sort_by
      this.$router.push({
        query: { ...this.$route.query, sort_by }, // page: value, limit: this.size
      })
    },
    onChangePage(page) {
      this.defaultPage = page
      this.$router.push({
        query: { ...this.$route.query, page },
      })
    },
    changeQuery(params = {}) {
      this.$router.push({
        query: {
          ...this.$route.query,
          ...params,
        },
      })
    },
    onChangeSearch(e) {
      this.valueSearch = e.target.value
    },
    onChangeAutoAccept() {
      if (!this.dataDrawerDetail.sales_channel || !this.dataDetailChannel.channel_id) {
        return this.$notification.error({
          message: 'Gagal',
          description: 'Sales Channel Tidak Ditemukan',
        })
      }
      const { sales_channel: { code: channelCode }, id: channelId } = this.dataDrawerDetail
      const { auto_accept: currentAutoAccept } = this.dataDetailChannel
      if (['tiktok', 'tiktok_id'].includes(channelCode)) {
        this.fetchingUpdateAutoAccept = true
        updateAutoAcceptChannel({
          business_id: this.$route.query.business_id,
          channel_code: channelCode,
          channel_id: channelId,
          data: {
            auto_accept: !currentAutoAccept,
          },
        })
        .then(({ data }) => {
          const notificationType = data.status.toLowerCase() === 'failed'
            ? 'error' : 'success'
          const notificationMessage = data.status.toLowerCase() === 'failed'
            ? 'Gagal' : 'Berhasil'
          const notificationDescription = currentAutoAccept
            ? 'Non Aktifkan' : 'Aktifkan'
          this.$notification[notificationType]({
            message: data?.code || `Ubah Auto Accept Order ${notificationMessage}`,
            description: data?.message || 
              `Status Auto Accept Order ${this.dataDrawerDetail?.title || this.dataDrawerDetail?.sales_channel?.name} ${notificationMessage} di ${notificationDescription}`,
          })
          if(data.status.toLowerCase() !== 'failed') {
            this.onShowDrawerDetail(this.dataDrawerDetail)
          }
          this.fetchingUpdateAutoAccept = false
        })
        .catch(err => {
          this.fetchingUpdateAutoAccept = false
          console.error(err)
          this.$notification.error({
            message: err?.code || 'Ubah Auto Accept Order Gagal',
            description: err?.message,
          })
        })
      }
    },
    async onShowDrawerDetail(item) {
      this.viewDetail = true
      this.dataDrawerDetail = item
      this.warehouseList = []
      
      if(['shopee_id', 'tokopedia_id'].includes(item.sales_channel.code)) {
        this.loadingWarehouse = true
        
        await getChannelWarehouse({
          business_id: this.$route.query.business_id,
          channel_code: item.sales_channel.code,
          channel_id: item.id,
          connected: true,
        })
          .then(response => {
            let tempWarehouseList = []
            if (['shopee_id', 'tokopedia_id'].includes(item.sales_channel.code)) {
              tempWarehouseList = response?.data
            } else {
              tempWarehouseList = response?.data?.data?.data
            }
            this.warehouseList = tempWarehouseList
            
          })
          .catch(err => {
            this.warehouseList = []
            console.error(err)
          })
          .finally(() => this.loadingWarehouse = false)
      } else if(['tiktok', 'tiktok_id'].includes(item.sales_channel.code)) {
        this.loadingWarehouse = true
        await Promise.all([
          this.$store.dispatch('warehouse/GETWAREHOUSELIST', {
            ...this.$route.query,
            channel_code: item.sales_channel.code,
          }),
          item.status === 'CONNECTED' ? getDetailChannel({
            business_id: this.$route.query.business_id,
            channel_code: item.sales_channel.code,
            channel_id: item.id,
          }) : Promise.resolve(),
        ])
          .then(([responseMaster, { data: { data: responseDetailChannel } }]) => {
            const responseWarehouseChannel = responseDetailChannel?.warehouses
            responseWarehouseChannel?.forEach(res => {
              const findMasterWarehouse = responseMaster.find(master => master.id === res.warehouse_id)
              if(findMasterWarehouse?.name && res.warehouse_id) {
                this.warehouseList.push({
                  warehouse_master_id: res.warehouse_id,
                  warehouse_master_name: findMasterWarehouse.name,
                  warehouse_channel_name: res.channel_warehouse_name,
                })
              }
            })
            if (responseDetailChannel?.channel_id) {
              this.dataDetailChannel = responseDetailChannel
            } else {
              this.dataDetailChannel = {}
            }
          })
          .catch(err => {
            this.warehouseList = []
            this.dataDetailChannel = {}
            console.error(err)
          })
          .finally(() => this.loadingWarehouse = false)
      } else if(['blibli_id'].includes(item.sales_channel.code)) {
        this.loadingWarehouse = true
        await Promise.all([
          this.$store.dispatch('warehouse/GETWAREHOUSELIST', {
            ...this.$route.query,
            channel_code: item.sales_channel.code,
          }),
          getBlibliPickupPoint({
            business_id: this.$route.query.business_id,
            channel_id: item.id,
          }),
        ])
          .then(([responseMaster, { data: { data: responseChannel } }]) => {
            responseChannel?.forEach(res => {
              const findMasterWarehouse = responseMaster.find(master => master.id === res.master_warehouse_id)
              if(findMasterWarehouse?.name && res.warehouse_map_state === 'MAPPED') {
                this.warehouseList.push({
                  warehouse_master_id: res.master_warehouse_id,
                  warehouse_master_name: findMasterWarehouse.name,
                  warehouse_channel_name: res.display_name,
                })
              }
            })
          })
          .catch(err => {
            this.warehouseList = []
            console.error(err)
          })
          .finally(() => this.loadingWarehouse = false)
      } else if(['lazada_id'].includes(item.sales_channel.code)) {
        this.loadingWarehouse = true
        getLazadaWarehouseChannelList({
          channel_id: item.id,
        })
          .then(({ data: { data: response } }) => {
            if (response?.length > 0) {
              this.warehouseList = response.map(item => {
                return {
                  ...item,
                  warehouse_channel_name: item.channel_warehouse_id?.toUpperCase(),
                  warehouse_master_name: item.name,
                }
              })
            } else {
              this.warehouseList = []
            }
          })
          .catch(err => {
            this.warehouseList = []
            console.error(err)
          })
          .finally(() => this.loadingWarehouse = false)
      }
    },
    openModalApprove(data) {
      this.modalApproveConfirmation = !this.modalApproveConfirmation
      this.selectedData = data
    },
    actionModalApproveConfirmation(id) {
      this.modalApproveConfirmation = !this.modalApproveConfirmation
      this.selectedData = id
    },
    onSuccessApprove() {
      this.modalApproveConfirmation = false
      this.selectedData = null
      this.$router.go()
    },
    openModalAddWarehouse(data) {
      this.showModalAddWarehouseChannel = !this.showModalAddWarehouseChannel
      this.selectedData = data
    },
    actionModalAddWarehouseChannel(id) {
      this.showModalAddWarehouseChannel = !this.showModalAddWarehouseChannel
      this.selectedData = id
    },
    onSuccessAddWarehouse() {
      this.showModalAddWarehouseChannel = false
      this.selectedData = null
      this.$router.go()
    },
    refreshPage() {
      window.location.reload()
    },
    onPopconfirmDisconnectStore(channelCode, channelId) {
      this.dataDisconnectStore = {
        channelCode,
        channelId,
      }
      this.showModalDisconnectStore = true
    },
    onDisconnectChannel({ channelCode, channelId }) {
      this.loadingModalDisconnectStore = true
      if (['shopee_id', 'tokopedia_id', 'lazada_id'].includes(channelCode)) {
        disconnectChannelWithPause({
          business_id: this.$route.query.business_id,
          channel_id: channelId,
          channel_code: channelCode,
        })
          .then(({ status }) => {
            return this.refreshPage()
          })
          .catch((err) => {
            this.$notification.error({
              message: "Terjadi kesalahan",
              description: "Store channel belum dapat diputuskan/disconnect",
            })
            console.error(err)
          })
      } else if (['tiktok', 'tiktok_id'].includes(channelCode)) {
        disconnectTiktokIntegration({
          business_id: this.$route.query.business_id,
          channel_id: channelId,
        })
          .then(({ status }) => {
            return this.refreshPage()
          })
          .catch((err) => {
            this.$notification.error({
              message: "Terjadi kesalahan",
              description: "Store channel belum dapat diputuskan/disconnect",
            })
            console.error(err)
          })
      } else {
        this.$notification.error({
          message: "Terjadi kesalahan",
          description: "Store channel belum dapat diputuskan/disconnect",
        })
      }
      this.handleShowModalDisconnectStore(false)
      this.loadingModalDisconnectStore = false
    },
    async onReconnectChannel(channelCode, channelId) {
      if (['shopee_id', 'tokopedia_id'].includes(channelCode)) {
        const res = await reconnectChannelWithPause({
          business_id: this.$route.query.business_id,
          channel_id: channelId,
          channel_code: channelCode,
        })
        return this.refreshPage()
      } else if (['tiktok', 'tiktok_id'].includes(channelCode)) {
        return this.$router.push({
          path: '/add-store',
          query: {
            ...this.$route.query,
            default_channel_code: 'tiktok,tiktok_id',
          },
        })
      }
      this.$notification.error({
        message: "Terjadi kesalahan",
        description: "Store channel belum dapat disambungkan/reconnect",
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.btn-search:not(:hover) {
  background-color: #2196f3;
  color: #fff;
}

.st-connect::before,
.st-pending::before,
.st-disconnected::before {
  content: '';
  display: inline-block;
  margin-right: 0.5rem;
  vertical-align: middle;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #ff477e;
  user-select: none;
}
.menu-disconnect {
  color: #f5222d;
}

.menu-reconnect {
  color: #52c41a;
}
.st-connect::before {
  background: #52c41a;
}

.st-pending::before {
  background: #faad14;
}

.st-disconnected::before {
  background: #f5222d;
}

.btn-edit:not(:hover) {
  background: #e3f2fd !important;
  border-color: #e3f2fd !important;
  color: #2196f3 !important;
}
.btn-del:not(:hover) {
  background: #ffebf1 !important;
  border-color: #ffebf1 !important;
  color: #ff477e !important;
}
.btn-url:not(:hover) {
  background: #ebfefe !important;
  border-color: #ebfefe !important;
  color: #07beb8 !important;
}

.add__button {
  color: #fff !important;
  background: #ff0854 !important;
}
.store-name {
  color: var(--kit-color-primary);
  cursor: pointer;
}
.drawer-container {
  gap: 10px 0px;

}
.action-btn {
  width: fit-content;
  button {
    margin: 3px;
  }
}
</style>

<style lang="scss">
.select-antd-default.ant-select-lg .ant-select-selection__rendered {
  line-height: 38px !important;
}
.warning {
  &-logo {
    margin-bottom: 20px;
    text-align: center;

    i {
      font-size: 96px;
    }
  }

  &-description {
    margin-bottom: 30px;
    text-align: center;
    font-size: 16px;
  }

  &-loading {
    margin-bottom: 30px;

    .ant-skeleton-title {
      width: 100% !important;
    }
  }
}
.whs .ant-skeleton-title {
  margin: 0 !important;
}
.binding-box {
  position: relative;
  width: 100%;
  margin-top: 2rem;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;

  &-image {
    position: absolute;
    width: 150px;
    padding: 5px;
    top: -18px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 30px;

    & > div {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
    }

    img {
      height: 24px;
      width: auto;
    }
  }

  &-checkbox {
    display: flex !important;
    align-items: flex-start;

    > span {
      margin-top: -5px;
      font-size: 12px;

      &.ant-checkbox {
        margin-top: 0;
      }
    }
  }

  h3 {
    margin: 1.25rem 0 1rem;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  }

  ol {
    padding-left: 15px;
  }

  li {
    padding-left: 12px;
    font-size: 12px;
  }
}
</style>
