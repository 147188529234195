<template>
  <a-modal
    width="800px"
    :visible="isVisible"
    :footer="null"
    @cancel="onCloseModal"
  >
    <h6>
      Ubah Mapping Gudang {{ channel?.sales_channel?.name }} - {{ channel?.title }}
    </h6>
    <a-spin :spinning="isLoading">
      <table class="table-mapping mt-4">
        <thead>
          <tr>
            <th style="width: 34%">
              Gudang Channel
            </th>
            <th style="width: 34%">
              Gudang Master
            </th>
            <th class="text-center" style="width: 12%">
              Status
            </th>
            <th class="text-center" style="width: 20%">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="!channelWarehouseList || !channelWarehouseList.length">
            <td colspan="4" class="text-center">
              No Data
            </td>
          </tr>
          <tr v-for="(warehouse, index) in channelWarehouseList" :key="index">
            <td>{{ warehouse.name }}</td>
            <td>
              <template v-if="warehouse.edit">
                <a-select
                  v-model="warehouse.warehouse_id"
                  class="w-100"
                  show-search
                  placeholder="Pilih Gudang Master"
                  option-filter-prop="children"
                  :options="masterWarehouseList"
                  :filter-option="filterOption"
                  @change="onChangeWarehouseMaster($event, index)"
                />
              </template>
              <template v-else>
                <span v-if="warehouse.warehouse_master_name">
                  {{ warehouse.warehouse_master_name }}
                </span>
                <span v-else style="font-style: italic; color: #999">
                  Belum ada gudang yang terhubung
                </span>
              </template>
            </td>
            <td class="text-center">
              <a-tag :color="warehouse.connected ? 'green' : 'red'" class="w-100">
                {{ !warehouse.connected ? 'Not' : '' }} Connected
              </a-tag>
            </td>
            <td class="text-center">
              <template v-if="!warehouse.edit">
                <a-tooltip v-if="!warehouse.connected" placement="top">
                  <template slot="title">
                    <span>Tambah Sinkronisasi Gudang</span>
                  </template>
                  <a-button :disabled="isLoading" @click="warehouse.edit = true">
                    <a-icon type="plus" />
                  </a-button>
                </a-tooltip>
                <a-tooltip v-else placement="top">
                  <template slot="title">
                    <span>Putuskan Sinkronisasi Gudang</span>
                  </template>
                  <a-popconfirm
                    placement="bottom"
                    title="Apakah kamu yakin ingin memutuskan sinkronisasi gudang?"
                    description=""
                    ok-text="Ya, putuskan sinkronisasi"
                    cancel-text="Tidak"
                    @confirm="onAction(index, 'delete')"
                  >
                    <a-button :disabled="isLoading">
                      <a-icon type="disconnect" />
                    </a-button>
                  </a-popconfirm>
                </a-tooltip>
              </template>
              <template v-else>
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Simpan & Sinkronisasi Gudang</span>
                  </template>
                  <a-button :disabled="isLoading" @click="onAction(index, 'save')">
                    <a-icon type="check" />
                  </a-button>
                </a-tooltip>
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Batalkan</span>
                  </template>
                  <a-button :disabled="isLoading" class="ml-3" @click="onCancelEdit(index)">
                    <a-icon type="close" />
                  </a-button>
                </a-tooltip>
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </a-spin>
  </a-modal>
</template>

<script>
import { getWarehouseList } from '@/api/warehouse'
import { getLazadaWarehouseChannelList, adjustLazadaMappingWarehouseChannel } from '@/api/channels/lazada'
import { getTokopediaWarehouseChannelList, adjustTokopediaMappingWarehouseChannel } from '@/api/channels/tokopedia'
import { getShopeeWarehouseChannelList, getWarehouseShopee } from '@/api/channels/shopee'
import { getTiktokWarehouseChannelList, adjusTiktoktMappingWarehouseChannel } from '@/api/channels/tiktok'
import { disconnecMappingWarehouseChannel, connectMappingWarehouseChannel, updateMappingWarehouseChannel } from '@/api/channels/index'

export default {
  name: 'ModalMappingWarehouse',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {},
    },
    back: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isVisible: false,
      isLoading: false,
      masterWarehouseList: [],
      channelWarehouseList: [],
    }
  },
  computed: {
    businessId() {
      return this.$store.state.user.restriction_base === 'Warehouse'
        ? Object.keys(this.$store.getters['user/businessListObjectByKey'])[0]
        : this.$route.query.business_id
    },
    channel() {
      return this.data
    },
  },
  watch: {
    visible(value) {
      this.isVisible = value
      if (value) {
        this.init()
      } else {
        this.masterWarehouseList = []
        this.channelWarehouseList = []
      }
    },
  },
  methods: {
    async init() {
      this.isLoading = true
      await Promise.all([this.fetchWarehouseMaster(), this.fetchWarehouseChanne()])
        .then(([responseMaster, responseChannel]) => {
          this.masterWarehouseList = responseMaster.map(master => ({
            value: master.id,
            label: master.name,
          }))
          this.channelWarehouseList = responseChannel.map(channel => {
            const warehouseId = channel?.warehouse_id || channel?.warehouse_master_id || null
            const findMasterWarehouse = responseMaster.find(master => master.id === warehouseId)
            return {
              ...channel,
              warehouse_id: warehouseId,
              prev_warehouse_id: warehouseId,
              original_warehouse_id: warehouseId,
              warehouse_master_name: findMasterWarehouse?.name || '',
              connected: !!findMasterWarehouse?.id || !!warehouseId,
              edit: false,
            }
          })
        })
        .catch(() => {
          this.masterWarehouseList = []
          this.channelWarehouseList = []
        })
        .finally(() => this.isLoading = false)
    },
    async fetchWarehouseMaster() {
      return await getWarehouseList({ business_id: this.businessId })
        .then(({ data: { data: response } }) => response || [])
        .catch(() => [])
    },
    async fetchWarehouseChanne() {
      let promises = []
      const bodyReq = {
        business_id: this.businessId,
        channel_id: this.channel.id,
      }
      if (this.channel.sales_channel.code === 'lazada_id') {
        promises.push(getLazadaWarehouseChannelList(bodyReq))
      } else if (this.channel.sales_channel.code === 'shopee_id') {
        promises.push(getWarehouseShopee(bodyReq))
        promises.push(getShopeeWarehouseChannelList(bodyReq))
      } else if (['tiktok', 'tiktok_id'].includes(this.channel.sales_channel.code)) {
        promises.push(getTiktokWarehouseChannelList(bodyReq))
      } else if (this.channel.sales_channel.code === 'tokopedia_id') {
        promises.push(getTokopediaWarehouseChannelList(bodyReq))
      }
      return await Promise.all(promises)
        .then(([{ data }, res]) => {
          if (this.channel.sales_channel.code === 'shopee_id') {
            const { data: { response } } = res
            const shopeeWarehouse = response && response.length
              ? response
              : [{ locationId: '', warehouseName: 'Default Warehouse' }]
            
            return shopeeWarehouse.map(shopee => {
              const findChannel = data?.find(channel => (channel.warehouse_channel_id === shopee.locationId) || (channel.warehouse_channel_name.toLowerCase() === 'default warehouse' && ['', 'IDZ'].includes(shopee.locationId)))
              const objWarehouse = findChannel?.id
                ? findChannel
                : {
                    id: null,
                    warehouse_channel_id: shopee.locationId,
                    warehouse_master_id: null,
                  }
              return {
                ...objWarehouse,
                name: shopee.warehouseName || '',
              }
            })
          }
          // else if (this.channel.sales_channel.code === 'tokopedia_id') {
          //   return data?.data?.map((item) => {
          //     return {
          //       ...item,
          //       warehouse_master_id: item.warehouse_id,
          //     }
          //   })
          // }
          return data?.data || []
        })
        .catch(() => [])
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0]?.text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    onChangeWarehouseMaster(value, idx) {
      let duplicateIndex = this.channelWarehouseList.findIndex((channel, index) => channel.warehouse_id === value && index != idx)
      if (duplicateIndex > -1) {
        if (this.channelWarehouseList[duplicateIndex].connected) {
          this.$message.error('Gudang yang Anda pilih sudah terhubung dengan channel lain')
          duplicateIndex = idx
        }
        this.channelWarehouseList[duplicateIndex].warehouse_id = this.channelWarehouseList[duplicateIndex].prev_warehouse_id
      } else {
        this.channelWarehouseList[idx].prev_warehouse_id = value
      }
    },
    onAction(index, action) {
      if (!this.channelWarehouseList[index].warehouse_id) {
        return this.$message.error('Gudang master belum dipilih')
      }

      if (!this.channel.sales_channel.code) {
        return this.$notification.error({ message: 'Gagal sinkronisasi gudang, kode channel tidak ditemukan' })
      }

      if (this.channel.sales_channel.code === 'shopee_id') {
        if (action === 'save') {
          this.onConnectShopee(index)
        } else {
          this.onDisconnectShopee(index)
        }
      } else if (['tiktok', 'tiktok_id'].includes(this.channel.sales_channel.code)) {
        this.onActionTiktok(index, action)
      } else if (this.channel.sales_channel.code === 'lazada_id') {
        this.onActionLazada(index, action)
      } else if (this.channel.sales_channel.code === 'tokopedia_id') {
        this.onActionTokopedia(index, action)
      } else {
        this.$message.info('Coming Soon')
      }
    },
    async onConnectShopee(index) {
      this.isLoading = true
      let promises = []
      const { id: channel_id, sales_channel: { code, name } } = this.channel
      const { id, warehouse_id, warehouse_master_name, warehouse_channel_id, name: warehouse_name, connected } = this.channelWarehouseList[index]
      const bodyReq = {
        business_id: this.businessId,
        channel_code: code,
        mapping_id: connected ? id : undefined,
        data: {
          channel: { id: channel_id, code, name },
          warehouse_master: connected
            ? {
                id: warehouse_id,
                name: warehouse_master_name,
              }
            : undefined,
          warehouse_channel: connected
          ? {
              id: warehouse_channel_id,
              name: warehouse_name,
            }
          : undefined,
          data: !connected
            ? [{
                warehouse_id,
                warehouse_channel: {
                  id: warehouse_channel_id,
                  name: warehouse_name,
                },
              }]
            : undefined,
        },
      }

      if (connected) {
        promises.push(updateMappingWarehouseChannel(bodyReq))
      } else {
        promises.push(connectMappingWarehouseChannel(bodyReq))          
      }

      await Promise.all(promises)
        .then(() => {
          this.init()
          this.$notification.success({ message: 'Berhasil menambahkan sinkronisi gudang' })
          this.$emit('onRefreshList')
        })
        .catch((err) => {
          console.error(err)
          this.isLoading = false
        })
    },
    async onDisconnectShopee(index) {
      this.isLoading = true
      await disconnecMappingWarehouseChannel({
        business_id: this.businessId,
        channel_code: this.channel.sales_channel.code,
        data: {
          channel_id: this.channel.id,
          mapped_ids: [this.channelWarehouseList[index].id],
        },
      })
      .then(() => {
        this.init()
        this.$notification.success({ message: 'Berhasil memutuskan sinkronisasi gudang' })
        this.$emit('onRefreshList')
      })
      .catch((err) => {
        console.error(err)
        this.isLoading = false
      })
    },
    async onActionTiktok(idx, action) {
      this.isLoading = true
      await adjusTiktoktMappingWarehouseChannel({
        business_id: this.businessId,
        channel_id: this.channel.id,
        data: {
          warehouses: this.channelWarehouseList.map((channel, index) => ({
            warehouse_id: index == idx && action === 'delete'
              ? null
              : channel.warehouse_id,
            channel_warehouse_id: channel.channel_warehouse_id,
            channel_warehouse_name: channel.name,
          })),
        },
      })
      .then(() => {
        this.init()
        this.$notification.success({ message: 'Berhasil sinkronisasi gudang'  })
        this.$emit('onRefreshList')
      })
      .catch((err) => {
        console.error(err)
        this.isLoading = false
      })
    },
    async onActionLazada(idx, action) {
      this.isLoading = true
      await adjustLazadaMappingWarehouseChannel({
        business_id: this.businessId,
        channel_id: this.channel.id,
        data: {
          warehouses: this.channelWarehouseList.map((channel, index) => ({
            warehouse_id: index == idx && action === 'delete'
              ? null
              : channel.warehouse_id,
            channel_warehouse_id: channel.channel_warehouse_id,
            channel_warehouse_name: channel.name,
          })),
        },
      })
      .then(() => {
        this.init()
        this.$notification.success({ message: 'Berhasil sinkronisasi gudang'  })
        this.$emit('onRefreshList')
      })
      .catch((err) => {
        console.error(err)
        this.isLoading = false
      })
    },
    async onActionTokopedia(idx, action) {
      this.isLoading = true
      await adjustTokopediaMappingWarehouseChannel({
        business_id: this.businessId,
        channel_id: this.channel.id,
        data: {
          warehouses: this.channelWarehouseList.map((channel, index) => ({
            warehouse_id: index == idx && action === 'delete'
              ? null
              : channel.warehouse_id,
            channel_warehouse_id: channel.channel_warehouse_id,
            channel_warehouse_name: channel.name,
          })),
        },
      })
      .then(() => {
        this.init()
        this.$notification.success({ message: 'Berhasil sinkronisasi gudang'  })
        this.$emit('onRefreshList')
      })
      .catch((err) => {
        console.error(err)
        this.isLoading = false
      })
    },
    onCancelEdit(index) {
      this.channelWarehouseList[index].warehouse_id = this.channelWarehouseList[index].original_warehouse_id
      this.channelWarehouseList[index].edit = false
    },
    onCloseModal() {
      this.$emit('onCancel', false)
    },
  },
}
</script>

<style lang="scss">
.table-mapping {
  width: 100%;

  tbody tr:hover td {
    background-color: #e1e5e9;
  }

  th {
    background-color: #f0f2f4;
    font-weight: 500;
  }

  th, td {
    padding: 11px;
    border-bottom: 1px solid #e4e9f0;
  }
}
</style>